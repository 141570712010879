import { useContext, useState } from "react"
import { Box, IconButton, Stack, Typography } from "../../../../node_modules/@mui/material/index";
import { EXTENSIONS, post } from "../../../helpers/requests";
import { useRefetchData } from "../../../helpers/CustomHooks";
import { FailedToLoadMessage } from "../../generic/FailedToLoadMessage";
import LoadingMessage from "../../generic/LoadingMessage";
import { AxoTraxAdminAuth } from "./AxoTraxAdminAuth";
import { MyDivider } from "../../generic/MyDivider";
import { TenantsTable } from "./TenantsTable";
import { CreateTenantPanel } from "./CreateTenantPanel";

export const TenantManagementPage = () => {
    const [authToken, setAuthToken] = useState(null);

    if(!authToken){
        return <AxoTraxAdminAuth setAuthToken={setAuthToken}/>
    }
    else {
        return <TenantManagement authToken={authToken}/>
    }
}

const TenantManagement = ({authToken}) => {
    const [tenantsData, refetchTenantsData] = useRefetchData(EXTENSIONS.GET_ALL_TENANTS, {token: authToken});

    if(tenantsData === false) return <FailedToLoadMessage/>
    if(tenantsData === null) return <LoadingMessage/>

    const tenants = tenantsData.data;

    return (
        <Stack s={2}>
            <Typography variant='h4' align='center'>Tenants</Typography>
            <Box m={2}>
                <TenantsTable authToken={authToken} tenants={tenants} refetchTenants={refetchTenantsData}/>
            </Box>
            <MyDivider/>
            <Box width={600} marginX={'auto'}>
                <CreateTenantPanel authToken={authToken} refetchTenants={refetchTenantsData}/>
            </Box>
        </Stack>
    )
}
