import React, { useEffect } from "react";
import Context from "../../Store";
import { useParams } from "../../../node_modules/react-router-dom/dist/index";
import MySingleSelectFIeld from "../non_form_fields/MySingleSelectField";
import { getDefaultValue } from "../../helpers/DefaultFormValueMaker";
import { CONTEXT_DEPENDENCIES, formatFormValues, makeTicketDeepCopy } from "../../helpers/TicketFormHelper";
import AbstractTable from "../tables/AbstractTable";
import { fieldValueString, multiLineStringToJSX } from "../../helpers/FieldDisplayFormatters";
import { Box, Button, IconButton, Stack, Typography, Tooltip } from "@mui/material";
// import { Box, Button, IconButton, Stack, Tooltip, Typography } from "../../../node_modules/@mui/material/index";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ErrorIcon from '@mui/icons-material/Error';
import EntityForm from "./EntityForm";
import { truncateString } from "../../helpers/CommonUtil";
import { validateForm } from "../../helpers/TicketFormValidationHelper";
import LoadButton from "../generic/LoadButton";
import { REQUEST_STATES } from "../../helpers/Constants";
import { EXTENSIONS, post } from "../../helpers/requests";
import { useData, useEnsureUpToDateConfigs, useInterval } from "../../helpers/CustomHooks";
import LoadingMessage from "../generic/LoadingMessage";
import { hasInstancePermissions } from "../permissions/perm_helper";
import { ALL_PERMS } from "../permissions/perm_constants";
import { FIELD_VALUE_TYPES } from "../inputs/FieldProps";
import { csvFileContentsToArray, exportEmptyEntitiesCSV } from "../../helpers/csv_downloader";
import EntityFieldColumnMapper, { csvObjsToEntitities } from "./EntityFieldColumnMapper";
import { getAllEntityChoices } from "../../helpers/StaticDataHelper";
import { FailedToLoadMessage } from "../generic/FailedToLoadMessage";

const IS_INITIAL_SUBMISSION = false;//extracted to const because for now 'false' does the job, but this is technically the initial submission
const ErrorsIcon = ({report, idx}) => {
    if(!report) return null;

    const {already_exists_and_active_errors, already_pending_errors, submission_errors, invalid_parent_errors, insufficient_permission_errors} = report;
    let msg = "";

    let err = already_exists_and_active_errors.find((e) => e.idx === idx);
    if(err) msg += `${err.msg}\n`
    
    err = already_pending_errors.find((e) => e.idx === idx);
    if(err) msg += `${err.msg}\n`

    err = submission_errors.find((e) => e.idx === idx);
    if(err) msg += `fix errors:\n${err.msg}\n`

    err = invalid_parent_errors.find((e) => e.idx === idx);
    if(err) msg += `invalid parent\n`

    err = insufficient_permission_errors.find((e) => e.idx === idx);
    if(err) msg += `You do not have permission to add this entity\n`

    if(msg === "") return null;

    return (
        <Tooltip title={<Typography fontSize={16}>{multiLineStringToJSX(msg)}</Typography>}>
            <ErrorIcon color={'error'} fontSize='large'/>
        </Tooltip>
    )
}


const TooltipButton = ({tooltip, btnText, color, onClick}) => {
    return (
        <Tooltip title={tooltip} enterDelay={1000}>
            <Button color={color} onClick={onClick} variant={'contained'}>
                {btnText}
            </Button>
        </Tooltip>
    )
}

const EntityFormActionBar = ({onCancelClick, onCloseClick, onNextClick, onCopyClick}) => {
    return (
        <Stack direction='row' spacing={1} marginY={2}>
            <TooltipButton btnText={"Remove"} onClick={onCancelClick} color={'error'} tooltip={"remove this entry and go back to table"}/>
            <TooltipButton btnText={"Done"} onClick={onCloseClick} tooltip={"keep this entry and go back to table"}/>
            <TooltipButton btnText={"Next (new)"} onClick={onNextClick} tooltip={"keep this entry, and start a new blank entry"}/>
            <TooltipButton btnText={"Next (copy)"} onClick={onCopyClick} tooltip={"keep this entry, and start a new entry with the same data as this one."}/>
        </Stack>
    )
}

const getParentChoicesUserCanAddMembersFor = (rootEntities, userData, staticData, instanceType) => {
    const output = [];
    for(const entity of rootEntities){
        const hasdPerm = hasInstancePermissions(userData, staticData, ALL_PERMS.ENTITY_ACTIONS.BULK_IMPORT.key, entity, instanceType);
        if(hasdPerm){
            output.push({value: entity.id, label: entity.name})
        }
    }
    return output;
}

const BulkImportEntitiesTable = ({entityType, isRoot, parentChoices, datas, setDatas, setOpenIndex, addNewEntityData, copyEntityData }) => {
    const {alertSuccess, alertError, staticData, user} = React.useContext(Context);
    const {entity_types, entity_summaries} = staticData;

    const instanceType = isRoot ? entityType.root_instance_type : entityType.member_instance_type;
    const structure = instanceType.structure;

    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);
    const [errorReport, setErrorReport] = React.useState(null);
        
    const onEditRowClick = (entityData) => {
        const index = datas.indexOf(entityData);
        setOpenIndex(index);
        // const newDatas = [...datas];
        // newDatas.splice(index, 1);
        // setDatas(newDatas);
    }
    const onDeleteRowClick = (entityData) => {
        const index = datas.indexOf(entityData);
        const newDatas = [...datas];
        newDatas.splice(index, 1);
        setDatas(newDatas);
    }

    const getColumnNames = () => {
        const output = ['', 'Name'];
        if(!isRoot) output.push(entityType.name)
        structure.sections.forEach(sectionStructure => {
            sectionStructure.fields.forEach(fieldStructure => {
                if(!sectionStructure.is_deleted && !fieldStructure.is_deleted){
                    output.push(fieldStructure.short_label)
                }
            })
        })
        return output;
    }

    const renderRowCells = (entityData) => {
        const idx = datas.indexOf(entityData);
        const output = [
            <Stack direction='row'>
                <ErrorsIcon report={errorReport} idx={idx}/>
                <IconButton onClick={() => copyEntityData(entityData)} disabled={isDisabled} color='primary'><ContentCopyIcon/></IconButton>
                <IconButton onClick={() => onEditRowClick(entityData)} disabled={isDisabled} color='primary'><EditIcon/></IconButton>
                <IconButton onClick={() => onDeleteRowClick(entityData)} disabled={isDisabled} color={'error'}><RemoveCircleIcon/></IconButton>
            </Stack>,
            entityData.name
        ];
        if(!isRoot){
            const parentChoice = parentChoices.find(c => c.value === entityData.parent);
            const parentName = parentChoice.label;
            output.push(parentName);
        }

        structure.sections.forEach(sectionStructure => {
            if(sectionStructure.is_deleted) return;
            sectionStructure.fields.forEach(fieldStructure => {
                if(fieldStructure.is_deleted) return;
                const fieldValue = entityData.values[sectionStructure.id][fieldStructure.id];
                const valueDisplay = fieldValueString(fieldValue, fieldStructure, staticData);
                const trancatedDisplay = truncateString(valueDisplay, 15, true);
                output.push(trancatedDisplay)
            })
        })
        return output;
    }

    const onSubmitClick = () => {
        const datasCopy = datas.map(entityData => {
            let formValuesCopy = makeTicketDeepCopy(entityData.values);
            formatFormValues(formValuesCopy, structure);
            return {...entityData, values: formValuesCopy};
        })
        const body = {
            entity_type_id: entityType.id,
            is_root: isRoot,
            entity_data_dicts: datasCopy
        }

        const onSuccess = (resp) => {
            alertSuccess("Successfully imported data");
            setSubmitState(REQUEST_STATES.SUCCEEDED);
        }
        
        const onFail = (err) => {
            console.log('bulk import error: ', err)
            setSubmitState(REQUEST_STATES.FAILED);
            if(err.response.status === 400){
                alertError("Failed to import. see table for details");
                setErrorReport(err.response.data);
            }
            else {
                alertError("Failed to import. Internal Server Error");
            }
        }
        post(EXTENSIONS.BULK_IMPORT_ENTITIES, body, onSuccess, onFail)
        setSubmitState(REQUEST_STATES.SENDING);
        setErrorReport(null);
    }
    const isSubmitting = (submitState === REQUEST_STATES.SENDING);
    const isDisabled = [REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState);
    return (
        <Box>
            <AbstractTable
                rows={datas}
                rowToKey={(data) => data.key}//maybe use parent+name+index for key?
                columnNames={getColumnNames()}
                renderRowCells={renderRowCells}
                rowHeight={'small'}
                initialRowsPerPage={100}
                />
            <Stack direction='row' spacing={1}>
                <Button variant='contained' onClick={() => addNewEntityData()} disabled={isDisabled}>Add</Button>
                <LoadButton onClick={onSubmitClick} loading={isSubmitting} disabled={isDisabled} variant={'contained'}>
                    Submit
                </LoadButton>
                <Button variant='contained' onClick={()=>setDatas([])} disabled={isDisabled} color='error'>
                    Clear all
                </Button>
            </Stack>
        </Box>
    )
}

// need to clean or format formvalues since server finds invalid file id for empty string (should be null)
const SingleEntityForm = ({entityType, isRoot, parentChoices, datas, setDatas, openIndex, setOpenIndex, addNewEntityData, copyEntityData}) => {
    const {alertSuccess, alertError, staticData, user} = React.useContext(Context);
    const instanceType = isRoot ? entityType.root_instance_type : entityType.member_instance_type;
    const structure = instanceType.structure;

    const openEntityData = datas[openIndex];
    
    const setName = (x) => {
        const isNameAlreadyUsed = (x !== "") && datas.some((d) => (d !== openEntityData) && (d.parent === openEntityData.parent) && (d.name.toString().toLowerCase() === x.toString().toLowerCase()));
        if(isNameAlreadyUsed){
            alertError(`You are already importing a ${entityType.name} with this name`, 3000);
        }

        const entityData = {...openEntityData};
        entityData.name = x;
        datas[openIndex] = entityData;
        setDatas([...datas]);
    }
    const setParentId = (x) => {
        const entityData = {...openEntityData};
        entityData.parent = x;
        datas[openIndex] = entityData;
        setDatas([...datas]);
    }
    // const setParent = (x) => { }
    const setFormValues = (x) => {
        const entityData = {...openEntityData};
        entityData.values = x;
        datas[openIndex] = entityData;
        setDatas([...datas]);
    }

    const clearFileFields = (formVals) => {
        structure.sections.forEach(sectionStructure => {
            sectionStructure.fields.forEach(fieldStructure => {
                const isFileField = fieldStructure.value_type === FIELD_VALUE_TYPES.FILE;
                if(isFileField && formVals[sectionStructure.id][fieldStructure.id]){
                    formVals[sectionStructure.id][fieldStructure.id] = '';
                }
            })
        })
    }

    const notifyErrors = () => {
        if(!openEntityData.name){
            alertError("Must provide a name");
            return true;
        }
        const isNameAlreadyUsed = datas.some((d) => (d !== openEntityData) && (d.parent === openEntityData.parent) && (d.name.toString().toLowerCase() === openEntityData.name.toString().toLowerCase()));
        if(isNameAlreadyUsed){
            alertError(`You are already importing a ${entityType.name} with this name`, 3000);
            return true;
        }
        if(!isRoot && !openEntityData.parent){
            alertError(`Must select a ${entityType.name} that this is a member of`);
            return true;
        }
        const formDataCopy = makeTicketDeepCopy(openEntityData.values);
        const contextValues = {
            [CONTEXT_DEPENDENCIES.EMP_ID_ON_ENTITY]: openEntityData.name
        }
        formatFormValues(formDataCopy, structure)
        const errors = validateForm(formDataCopy, structure, IS_INITIAL_SUBMISSION, contextValues);
        const hasErrors = (errors.length !== 0);
        if(hasErrors){
            let msg = "Please fix the following errors\n";
            msg += errors.join("\n");
            alertError(msg, null);
            return true;
        }
        return false;
    }

    const formatOpenEntityData = () => {
        let formValuesCopy = makeTicketDeepCopy(openEntityData.values);
        clearFileFields(formValuesCopy);
        formatFormValues(formValuesCopy, structure);
        const entityDataCopy = {...openEntityData, values: formValuesCopy};
        datas[openIndex] = entityDataCopy;
        setDatas([...datas]);
    }

    const onCloseClick = () => {
        // alertError("not checking error", 1000);
        const hasErrors = notifyErrors();
        if(!hasErrors){
            formatOpenEntityData();
            setOpenIndex(null);
        }
    }

    const onCancelClick = () => {
        const newDatas = [...datas];
        newDatas.splice(openIndex, 1);
        setDatas(newDatas);
        setOpenIndex(null);
    }

    const onNextClick = () => {
        const hasErrors = notifyErrors();
        if(!hasErrors){
            formatOpenEntityData();
            addNewEntityData()
        }        
    }

    const onCopyClick = () => {
        const hasErrors = notifyErrors();
        if(!hasErrors){
            formatOpenEntityData();
            copyEntityData(openEntityData)
        }        
    }

    return(
        <Box>
            <EntityFormActionBar onCancelClick={onCancelClick} onCloseClick={onCloseClick} onNextClick={onNextClick} onCopyClick={onCopyClick} />
            <Typography>*Note that files cannot be uploaded with bulk imports and will be ignored</Typography>
            <EntityForm
                entityType={entityType}
                isRoot={isRoot}
                parentChoices={parentChoices}
                parentId={openEntityData.parent}
                setParentId={setParentId}
                name={openEntityData.name}
                setName={setName}
                formValues={openEntityData.values}
                setFormValues={setFormValues}
                isInitialSubmission={IS_INITIAL_SUBMISSION}
                editableSectionIds={'*'}
                />
            <EntityFormActionBar onCancelClick={onCancelClick} onCloseClick={onCloseClick} onNextClick={onNextClick} onCopyClick={onCopyClick} />
        </Box>
    )
}

const getStorageKey = (entityType, isRoot) => `entity-bulk-import-${entityType.id}-${isRoot}`;

const BulkImportEntities = ({entityType, isRoot, datas, setDatas}) => {
    const {alertSuccess, alertError, staticData, user} = React.useContext(Context);
    const {entity_types, entity_summaries} = staticData;

    const instanceType = isRoot ? entityType.root_instance_type : entityType.member_instance_type;
    const structure = instanceType.structure;
    
    const [lastSavedDatas, setLastSavedDatas] = React.useState(null);
    const [openIndex, setOpenIndex] = React.useState(null);
    const [nextKey, setNextKey] = React.useState(1);
    const [validParentchoices, setValidParentChoices] = React.useState(null);

    // #region restore and backup
    // //try to locally fetch previously stored data and set to state
    // useEffect(() => {
    //     if(datas && (datas.length > 0)) return; //dont overwrite if data is present - may be from csv
    //     const key = getStorageKey(entityType, isRoot);
    //     const prevDatasStr = localStorage.getItem(key);
    //     console.log('trying to fetch prev state', {prevDatasStr});
    //     if(!prevDatasStr || (prevDatasStr === 'null')) return;

    //     const prevDatas = JSON.parse(prevDatasStr);
    //     //TODO: check that structure still matches and transform if necesary
    //     setDatas(prevDatas);
    //     setLastSavedDatas(prevDatas);
        
    // }, [])

    // //store formdata every 5 seconds if it changed
    // const tryUpdate = () => {
    //     console.log('might update storage');
    //     if((!datas) || !datas.length || (lastSavedDatas === datas)) return;
    //     const dataStr = JSON.stringify(datas);
    //     const key = getStorageKey(entityType, isRoot);
    //     localStorage.setItem(key, dataStr);
    //     setLastSavedDatas(datas);
    //     console.log('updated storage');
    // }
    // useInterval(tryUpdate, 5000)
    // #endregion restore and backup


    React.useEffect(() => {
        if(isRoot){
            setValidParentChoices([])
        }
        else{
            const onSuccess = (resp) => {
                const instances = resp.data.data;
                const parentIds = getParentChoicesUserCanAddMembersFor(instances, user, staticData, entityType.root_instance_type);
                setValidParentChoices(parentIds);
            }
            const onFail = (err) => {
                setValidParentChoices(false);
            }
            const body = {entity_type_id: entityType.id, parent_id: null}
            post(EXTENSIONS.GET_ALL_ENTITIES, body, onSuccess, onFail);
        }
    }, [entityType.id, isRoot])

    if(validParentchoices === false) return <div>Failed to load</div>;;
    if(validParentchoices === null) return <LoadingMessage/>;
    // const entities = rootInstancesData.data;

    const addNewEntityData = () => {
        const newDatas = [...datas];
        const values = getDefaultValue(structure);
        const parent = isRoot ? null : "";
        const newEntityData = {name: "", parent: parent, values: values, key: nextKey};
        newDatas.push(newEntityData);
        setDatas(newDatas);
        setOpenIndex(newDatas.length - 1);
        setNextKey(nextKey + 1);
    }
    
    const copyEntityData = (entityData) => {
        // const index = datas.indexOf(entityData);
        // console.log('copying: ', {index, entityData, idxWithKey: datas.findIndex(e => e.key === entityData.key)})
        const newDatas = [...datas];
        // const values = makeTicketDeepCopy(datas[index].values);
        const values = makeTicketDeepCopy(entityData.values);
        const newEntityData = {name: "", parent: entityData.parent, values: values, key: nextKey};
        newDatas.push(newEntityData);
        setDatas(newDatas);
        setOpenIndex(newDatas.length - 1);
        setNextKey(nextKey + 1);
    }

    //TODO: ignore file fields and irrelevant sections
    if(openIndex === null){
        return (
            <BulkImportEntitiesTable
                entityType={entityType}
                datas={datas}
                setDatas={setDatas}
                setOpenIndex={setOpenIndex}
                addNewEntityData={addNewEntityData}
                copyEntityData={copyEntityData}
                isRoot={isRoot}
                parentChoices={validParentchoices}
                />
        )
    }
    else{
        return (
            <SingleEntityForm
                entityType={entityType}
                datas={datas}
                setDatas={setDatas}
                openIndex={openIndex}
                setOpenIndex={setOpenIndex}
                isRoot={isRoot}
                parentChoices={validParentchoices}
                addNewEntityData={addNewEntityData}
                copyEntityData={copyEntityData}
                />
        )
    }
}

const FileSelectButton = ({txt, onSelected, btnVariant, accept}) => {
    const labelRefId = 'FileSelectButton';
    return (
        <Box>
            <input
                // accept="image/*"
                style={{ display: 'none' }}
                id={labelRefId}
                multiple
                type="file"
                onChange={(e) => onSelected(e.target.files[0])}
                key={txt}
                accept={accept}
                />
            <label htmlFor={labelRefId}>
                <Button variant={btnVariant} component="span">
                    {txt}
                </Button>
            </label>
        </Box>
    )
}
const BulkEntityImportPage = () => {
    const {alertSuccess, alertError, staticData, user} = React.useContext(Context);
    const {entity_types, entity_summaries} = staticData;
    const configSyncState = useEnsureUpToDateConfigs();

    const params = useParams();
    const entity_type_id = Number(params.entity_type_id);

    const [isForRootEntities, setIsForRootEntities] = React.useState("");
    const [datas, setDatas] = React.useState([]);
    const [isMethodSelected, setIsMethodSelected] = React.useState(false);
    const [selectedCsvData, setSelectedCsvData] = React.useState(null);
    const [isMappingComplete, setIsMappingComplete] = React.useState(false);

    useEffect(() => { //auto set select Root type if there is no member type
        if(!configSyncState) return;
        const entityType = entity_types.find(et => et.id === entity_type_id);
        if(!entityType.member_instance_type){
            setIsForRootEntities(true);
        }
    }, [configSyncState])

    const awaitedDatas = [configSyncState];
    if(awaitedDatas.includes(false)) return <FailedToLoadMessage/>;
    if(awaitedDatas.includes(null)) return <LoadingMessage/>;

    const entityType = entity_types.find(et => et.id === entity_type_id);

    if(isForRootEntities === ""){
        const choices = [
            {value: true, label: `${entityType.name}s`},
            {value: false, label: `${entityType.name} members`},
        ]
        return (
            <MySingleSelectFIeld
                label={"What are you importing"}
                value={isForRootEntities}
                setValue={setIsForRootEntities}
                choices={choices}
                fullWidth={true}
                />
        )
    }

    if(!isMethodSelected){
        const onFileSelected = (file) => {
            console.log('file selected', file);
            try {
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const contents = e.target.result;
                        const csvData = csvFileContentsToArray(contents);
                        // console.log('csvData', csvData);
                        setSelectedCsvData(csvData);
                        setIsMethodSelected(true);
                    };
                    reader.readAsText(file);
                }
            } catch (error) {
                alertError("Error reading the CSV file.");
                console.error("Error reading the CSV file.", error)
            }
        }

        const instanceType = isForRootEntities ? entityType.root_instance_type : entityType.member_instance_type
        return (
            <Box>
                <Typography>How do you want to import the data?</Typography>
                <Button onClick={() => setIsMethodSelected(true)}>
                    Manually
                </Button>
                <FileSelectButton txt={'From csv'} onSelected={onFileSelected} accept={".csv"}/>
                <Button onClick={() => exportEmptyEntitiesCSV(instanceType)}>
                    Download Empty CSV
                </Button>
            </Box>
        )
    }

    if(selectedCsvData && !isMappingComplete){
        const colNames = Object.keys(selectedCsvData[0]);
        const onMappingComplete = (mappings) => {
            const instanceType = isForRootEntities ? entityType.root_instance_type : entityType.member_instance_type;
            const structure = instanceType.structure;
            const parentChoices = isForRootEntities ? [] : getAllEntityChoices(staticData, entityType.root_instance_type.id, null)
            const formattedData = csvObjsToEntitities(structure, selectedCsvData, mappings, parentChoices);
            setDatas(formattedData);
            setIsMappingComplete(true)
        }
        const onCancelClick = () => {
            setSelectedCsvData(null);
            setIsMethodSelected(false);
        }
        return (
            <EntityFieldColumnMapper
                entityType={entityType}
                isRoot={isForRootEntities}
                columnNames={colNames}
                onCompleteClick={onMappingComplete}
                onCancelClick={onCancelClick}
                />
        )
    }

    return <BulkImportEntities entityType={entityType} isRoot={isForRootEntities} datas={datas} setDatas={setDatas}/>
}

export default BulkEntityImportPage;