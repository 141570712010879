import {AppBar, Autocomplete, Box, Button, ButtonGroup, Grid, IconButton, Stack, TextField, Toolbar, Typography} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// import logo from '../logo.png'
import axotrax_logo from '../../axotrax_logo.png'
import { useNavigate } from 'react-router-dom';
import { PROVIDE_FEEDBACK } from '../../App';
import React, { useState } from 'react';
import ConfirmActionDialog from '../generic/ConfirmActionDialog';
import FeedbackFormDialog from './FeedbackFormDialog';
import Context from '../../Store';
import { makeNestedDeepCopy } from '../../helpers/TicketFormHelper';
import GlobalSearchBar from './GlobalSearchBar';
import { useTenantNavigate } from '../../helpers/CustomHooks';
import { post } from '../../helpers/requests';


const Header = ({user, onLogoutClick}) => {
    const {logged_in, is_system_admin, is_system_super_admin, full_name} = user
    const [feedbackFormOpen, setFeedbackFormOpen] = useState(false)
    const [isSearchBarOpen, setIsSearchBarOpen] = useState(false)

    const navigate = useTenantNavigate()

    let headerText = "AxoTrax";//"Software and Vendor Management";// + (logged_in ? logged_in : "")
    // if(!logged_in) headerText += "Not logged in"
    // else{
    //     headerText += logged_in
    //     // if(is_system_super_admin) headerText += " (Super Admin)"
    //     // else if(is_system_admin) headerText += " (Admin)"
    // }
    const name_msg = full_name ? (`Welcome, ${full_name}`) : "Not logged in"

    const onFeedbackClick = () => {
        // navigate(PROVIDE_FEEDBACK)
        setFeedbackFormOpen(true)
    }

    return(
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar >

                <Stack direction={"row"} alignItems={"center"} width={"33%"} marginTop={1}>
                    <Box onClick={()=> navigate('/')} sx={{cursor: 'pointer'}}>
                        <img src={axotrax_logo} height={45} alt='logo'/>
                    </Box>
                    <Typography variant="h6" noWrap component="div" marginLeft={2}>
                        {headerText}
                    </Typography>
                </Stack>

                {/* <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} width={"34%"}>
                    <Typography variant="h6" noWrap>
                        {name_msg}
                    </Typography>
                </Stack> */}
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} width={"34%"}>
                    <GlobalSearchBar/>
                </Stack>

                <Stack direction={"row"} alignItems={"center"} sx={{ marginLeft: "auto" }}  width={"33%"}>
                    <ButtonGroup variant="filled" sx={{ marginLeft: "auto" }}>
                        {/* <Button onClick={() => navigate("temp")}>Temp</Button> */}
                        {/* <Button onClick={() => post('savas/initialize_new_tenant', {})}>New Tenant</Button> */}
                        <Button onClick={onFeedbackClick}>Feedback</Button>
                        <Button onClick={onLogoutClick}>Logout</Button>
                    </ButtonGroup>
                </Stack>

            </Toolbar>
            {
                feedbackFormOpen &&
                <FeedbackFormDialog handleCancel={() => setFeedbackFormOpen(false)}/>
            }
        </AppBar>
    )
}

export default Header;