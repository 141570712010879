import { getPendingActivenessTicket } from "./CommonUtil"
import { fieldValueString } from "./FieldDisplayFormatters"

const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })

    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}



export const exportToCsv = () => {
    // e.preventDefault()

    // Headers for each column
    let headers = ['Id,Name,Surname,Age']

    
    // Convert users data to a csv
    // let usersCsv = usersData.users.reduce((acc, user) => {
    //     const { id, name, surname, age } = user
    //     acc.push([id, name, surname, age].join(','))
    //     return acc
    // }, [])
    
    const usersCsv = ["1,A1,A2,1", "2,B1,B2,2"]
    downloadFile({
        data: [...headers, ...usersCsv].join('\n'),
        fileName: 'users.csv',
        fileType: 'text/csv',
    })
}

export const exportArrayToCsv = (objs, fileName) => {
    // e.preventDefault()

    // Headers for each column
    let headers = Object.keys(objs[0])

    // Convert users data to a csv
    let csv = headers.join(",") + "\n"

    for(let objIdx=0; objIdx<objs.length; objIdx++){
        let obj = objs[objIdx]
        const line = objToCsvLine(obj, headers) + "\n";
        csv += line;

        // for(let keyIdx=0; keyIdx<headers.length; keyIdx++){
        //     let key = headers[keyIdx]
        //     let cell = obj[key]
        //     if(![null, undefined].includes(cell)){
        //         cell = cell.toString().replaceAll('"', "'")
        //         cell = cell.replaceAll("\n", ",")
        //     }
        //     csv += `"${cell}"`

        //     // either seperate cell values with comma, or with new line if it is last value for row
        //     if(keyIdx !== headers.length - 1) csv += ","
        //     else csv += "\n"
        // }
    }

    downloadFile({
        data: csv,
        fileName: fileName + ".csv",
        fileType: 'text/csv',
    })
}

export const exportTicketsToCsv = (tickets, fileName) => {
    const flattennedTickets = tickets.map(ticket => flattenTicket(ticket))
    exportArrayToCsv(flattennedTickets, fileName)
}

const flattenTicket = (ticket) => {
    console.log('Not implemented')
    return null;
}
const objToCsvLine = (object, headers) => {
    //need to test/check func
    return headers.map((header) => {
        let value = object[header] !== undefined ? object[header] : '';
        if(typeof value === 'string'){
            if(value.includes('link #2')){
                console.log('value', {before: value})
            }
            // If the value contains double quotes, escape them with double quotes
            if (value.includes('"')) {
              value = value.replaceAll(/"/g, '""');
            }
            // If the value contains commas or starts/ends with spaces, enclose it in double quotes
            if (value.includes(',') || /^\s|\s$/g.test(value)) {
              value = `"${value}"`;
            }
            // replace newlines
            value = value.replaceAll("\n", "\\n")
            if(value.includes('link #2')){
                console.log('value', {after: value})
            }
        }
        return value;
      }).join(',')
}

const boolToText = (b) => {
    return b ? "Yes" : "No";
}

const fieldToColumnName = (section, field) => {
    return `${section.metadata.label} - ${field.short_label} (${section.id}-${field.id})`;
}

const entityToFlatEntry = (instanceType, entity, staticData) => {
    //id, name, is_self_active
    //parent_id, parent_name, is_parent_self_active
    const output = {
        id: entity.id,
        name: entity.name,
        is_active: boolToText(entity.is_active),
        is_self_active: boolToText(entity.state.is_self_active),
    }
        
    if(entity.parent){
        Object.assign(output, {
            parent_id: entity.parent.id,
            parent_name: entity.parent.name,
            is_parent_active: boolToText(entity.parent.is_active),
        })
    }
    let formValues = null;
    if(entity.state.is_self_active){
        formValues = entity.state.form_values;
    }
    else{
        const pendingStateTicket = getPendingActivenessTicket(entity);
        if(pendingStateTicket) formValues = pendingStateTicket.form_values;
    }
    
    const structure = instanceType.structure;
    for(const section of structure.sections){
        if(section.is_deleted) continue;

        for(const field of section.fields){
            if(field.is_deleted) continue;

            // const colName = `${section.metadata.label} - ${field.short_label} (${section.id}-${field.id})`;
            const colName = fieldToColumnName(section, field);
            if(formValues){
                const fieldValue = formValues[section.id]?.[field.id];
                const colValueText = fieldValueString(fieldValue, field, staticData)
                output[colName] = colValueText;
            }
            else{
                output[colName] = '';
            }
        }
    }
    return output;
}

export const exportEmptyEntitiesCSV = (instanceType) => {
    const headers = ['name', 'parent', 'accessor', 'resource']

    const structure = instanceType.structure;
    for(const section of structure.sections){
        if(section.is_deleted) continue;

        for(const field of section.fields){
            if(field.is_deleted) continue;
            const colName = fieldToColumnName(section, field);
            headers.push(colName)
        }
    }

    let csv = headers.join(",") + "\n"

    downloadFile({
        data: csv,
        fileName: `${instanceType.name}.csv`,
        fileType: 'text/csv',
    })
}

export const exportEntitiesToCsv = (filename, instanceType, entities, staticData) => {
    const entries = entities.map(e => entityToFlatEntry(instanceType, e, staticData));
    exportArrayToCsv(entries, filename)
}

// #region import
export const csvFileContentsToArray = (contents) => {
    const lines = contents.split(/\r\n|\n/).filter(line => line.length > 0);
    const headers = lines[0].split(',');

    const data = lines.slice(1).map((line) => {
    //   const values = line.split(',');
      const values = parseCsvLine(line);
      return headers.reduce((obj, header, index) => {
        const val  = values[index].trim();
        obj[header.trim()] = val;
        return obj;
      }, {});
    });

    return data;
}

const parseCsvLine = (line) => {
    const values = [];
    let current = '';
    let insideQuotes = false;

    for (let i = 0; i < line.length; i++) {
        const char = line[i];
        if (char === '"') {
            // Check if the quote is not an escaped quote within the quoted content
            if (insideQuotes && line[i + 1] === '"') {
                current += '"';
                i++; // Skip the next quote
            }
            else {
                insideQuotes = !insideQuotes;
            }
        }
        else if (char === ',' && !insideQuotes) {
            values.push(current);
            current = '';
        } 
        else {
            current += char;
        }
    }

    values.push(current); // Push the last value

    // Trim leading and trailing spaces for each value
    return values.map(value => value.trim());
};
// const old_parseCsvLine = (line) => {
//     const values = [];
//     let current = '';
//     let insideQuotes = false;

//     for (let i = 0; i < line.length; i++) {
//       const char = line[i];
//       if (char === '"') {
//         insideQuotes = !insideQuotes;
//       } else if (char === ',' && !insideQuotes) {
//         values.push(current);
//         current = '';
//       } else {
//         current += char;
//       }
//     }

//     values.push(current); // Push the last value

//     return values;
//   };

// #endregion import
