import { useContext, useState } from "react"
import AbstractTable from "../../tables/AbstractTable";
import { Box, Button, IconButton, Stack, Typography } from "../../../../node_modules/@mui/material/index";
import { EXTENSIONS, post } from "../../../helpers/requests";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TicketTextField from "../../inputs/TicketTextField";
import LoadButton from "../../generic/LoadButton";
import { SX_BOX_SIMPLE } from "../../../helpers/common_sx";
import Context from "../../../Store";


export const TenantsTable = ({tenants, refetchTenants, authToken}) => {
    const [tenantToDelete, setTenantToDelete] = useState(null);
   
    const columnNames = ['', 'ID', 'Company', 'Subdomain']

    const renderRowCells = (row) => {
        const tenant = row;
        const deleteButton = (
            <IconButton onClick={() => setTenantToDelete(tenant)}>
                <DeleteForeverIcon color='error'/>
            </IconButton>
        )
        return [deleteButton, row.id, row.name, row.subdomain]
    }

    if(tenantToDelete){
        const onTenantDeleted = () => {
            setTenantToDelete(null);
            refetchTenants();
        }
        return (
            <Box marginX='auto' maxWidth={600}>
                <ConfirmDeleteTenantPanel
                    tenant={tenantToDelete}
                    onCancelClick={()=>setTenantToDelete(null)}
                    onTenantDeleted={onTenantDeleted}
                    authToken={authToken}
                    />
            </Box>
        )
    }

    return (
        <AbstractTable
            rows={tenants}
            rowToKey={t => t.id}
            columnNames={columnNames}
            renderRowCells={renderRowCells}
            initialRowsPerPage={10}
            rowsPerPageOptions={[5, 10, 100, tenants.length]}
            // renderRowExpansion={undefined}
            // onRowClick={undefined}
            // onRowHover={undefined}
            // rowHeight={'small'}
            // expandedRow={undefined}
            />
    )
}

const ConfirmDeleteTenantPanel = ({tenant, onCancelClick, onTenantDeleted, authToken}) => {
    const {alertSuccess, alertError} = useContext(Context);
    const [subdomainInput, setSubdomainInput] = useState('');
    const [isSubmitting, setIsSubmitting] = useState('');

    const doesInputMatch = (subdomainInput === tenant.subdomain);
    const onConfirmDeleteClick = () => {
        if(!doesInputMatch){
            alertError(`Type "${tenant.subdomain}" into the text box in order to delete the tenant and its data.`)
        }
        const onSuccess = (resp) => {
            setIsSubmitting(false)
            alertSuccess('Deleted')
            onTenantDeleted();
        }

        const onFail = (err) => {
            setIsSubmitting(false)
            alertError(err.response.data)
        }
        
        setIsSubmitting(true)
        post(EXTENSIONS.DELETE_TENANT_DATA, {token: authToken, tenant_id: tenant.id}, onSuccess, onFail)
    }

    return (
        <Box sx={SX_BOX_SIMPLE}>
            <Typography>
                Are you sure you want to delete the tenant with subdomain {tenant.subdomain}?
            </Typography>
            <Typography>
                This operation is permanent.
            </Typography>
            <Typography>
                To continue, type "{tenant.subdomain}" into the textbox below and click delete
            </Typography>
            <Stack direction='row' alignItems='center' spacing={1}>
                <TicketTextField
                    label={''}
                    value={subdomainInput}
                    setValue={setSubdomainInput}
                    />
                <LoadButton
                    onClick={onConfirmDeleteClick} loading={isSubmitting} disabled={isSubmitting || !doesInputMatch}
                    variant={'contained'} color={'error'}
                    >
                        Delete
                </LoadButton>

                <Button onClick={onCancelClick} variant='contained' height='auto'>
                    Cancel
                </Button>
            </Stack>
        </Box>
    )
}