import React from "react";
import { useEffect } from "react";
// import jwtDecode from "jwt-decode";
import { Box, Typography } from "@mui/material";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

export const AxoTraxAdminAuth = ({setAuthToken}) => {
    const signDivId = "signInDivAdmin1938473"
    // const {alertSuccess, alertError } = React.useContext(Context);
    // const [errMsg, setErrMsg] = React.useState(null);
    // const [isLoggingIn, setIsLoggingIn] = React.useState(false);

    function handleCallbackResponse(response){
        const token = response.credential;
        setAuthToken(token)
    }


    const initAndRenderGoogleSignIn = () => {
        const google = window.google;

        try {
            google.accounts.id.initialize({
                client_id: CLIENT_ID,
                callback: handleCallbackResponse,
                cancel_on_tap_outside: false,
                prompt_parent_id: signDivId,
                // ux_mode: "redirect",//"popup"
                itp_support: true,
            });
        } catch(err){ 
            console.log("Failed to initialize google.")
        }

        try {
            google.accounts.id.renderButton(
                document.getElementById(signDivId),
                {
                    theme: "outline",
                    size: "large",
                    type: "standard",
                    text: "continue_with",
                    shape: "rectangular",
                    width: 265
                }
            );
        } catch(err){ 
            console.log("Failed to render Google sign in button.")
        }

    }

    useEffect(initAndRenderGoogleSignIn, [])


    return(
        <Box bgcolor={'#cccccc'} sx={{width: '100vw', height:'100vh'}}>
            <br/>
            <Typography variant="h3" align="center">AxoTrax Admins</Typography>
            <Typography variant="h5" align="center" marginY={3}>Please sign in</Typography>
            
            <div
                id={signDivId}
                align="center"
                type="icon"
                >
            </div>
        </Box>
    )
}