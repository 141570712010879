import { useContext, useState } from "react"
import { Box, IconButton, Stack, Typography } from "../../../../node_modules/@mui/material/index";
import TicketTextField from "../../inputs/TicketTextField";
import LoadButton from "../../generic/LoadButton";
import { EXTENSIONS, post } from "../../../helpers/requests";
import { SX_BOX_SIMPLE } from "../../../helpers/common_sx";

export const CreateTenantPanel = ({authToken, refetchTenants}) => {
    // const {alertSuccess, alertError} = useContext(Context);

    const [ownerEmail, setOwnerEmail] = useState('');
    const [ownerFirstName, setOwnerFirstName] = useState('');
    const [ownerLastName, setOwnerLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [subdomain, setSubdomain] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onSubmitClick = () => {
        const body = {
            token: authToken,
            owner_email: ownerEmail,
            owner_f_name: ownerFirstName,
            owner_l_name: ownerLastName,
            company_name: companyName,
            subdomain: subdomain,
        }

        const onSuccess = (resp) => {
            setIsSubmitting(false);
            // alertSuccess('Created!');
            refetchTenants()
        }
        
        const onFailure = (err) => {
            setIsSubmitting(false);
            setErrorMessage(err.response.data);
        }
        
        setErrorMessage('')
        setIsSubmitting(true);
        post(EXTENSIONS.INIT_NEW_TENANT, body, onSuccess, onFailure)
    }

    return (
        <Box sx={SX_BOX_SIMPLE}>
            <Typography variant='h6' align='center'>
                Create new Tenant
            </Typography>
            <Stack spacing={1} m={2}>
                <TicketTextField
                    value={ownerEmail}
                    setValue={setOwnerEmail}
                    label={`Owner's Email`}
                    fullWidth
                    />
                <TicketTextField
                    value={ownerFirstName}
                    setValue={setOwnerFirstName}
                    label={`Owner's first name`}
                    fullWidth
                    />
                <TicketTextField
                    value={ownerLastName}
                    setValue={setOwnerLastName}
                    label={`Owner's Last name`}
                    fullWidth
                    />
                <TicketTextField
                    value={companyName}
                    setValue={setCompanyName}
                    label={`Company name`}
                    fullWidth
                    />
                <TicketTextField
                    value={subdomain}
                    setValue={setSubdomain}
                    label={`Subdomain / extension`}
                    fullWidth
                    />
            </Stack>
            <Box align='center'>
                <LoadButton onClick={onSubmitClick} loading={isSubmitting} disabled={isSubmitting} variant={'contained'}>
                    Submit
                </LoadButton>
                <Typography color='error'>
                    {errorMessage}
                </Typography>
            </Box>
        </Box>
    )
}