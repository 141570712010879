import React from "react";
import { BrowserRouter, Outlet, Route, Routes, useLocation, useParams, Navigate } from "react-router-dom";
import Layout from "./components/app/Layout";
import Context, {ContextProvider} from "./Store";
import PendingTicketsPage from "./components/pages/PendingTicketsPage";
import RequestPage from "./components/pages/RequestPage";
import ApprovedPage from "./components/pages/ApprovedPage";
import PendingTasksPage from "./components/pages/PendingTasksPage";
import CompletedTasksPage from "./components/pages/CompletedTasksPage";
import ViewEntityPage from "./components/pages/ViewEntityPage";
import ReviewTicketPage from "./components/pages/ReviewTicketPage";
import PaperTrailPage from "./components/pages/PaperTrailPage";
import {
  PENDING, REQUEST, APPROVED, PAPER_TRAIL, TASKS, PENDING_TASKS, COMPLETED_TASKS, UPLOAD_CSV, VIEW_ALL, PROBLEMS,
  ACCESS, BULK_IMPORT, MANAGE_LEVELS, ADMIN_NEW, PERMISSIONS_NEW, MANAGED_TABLES,
  EMP_TAGS,
  CONFIGS,
  HRIS_INTEGRATION} from "./components/app/LayeredNavPanel" 
import {HRIS} from "./components/app/LayeredNavPanel" 
import UploadCSVPage from "./components/pages/UploadCsvPage";
import AdminViewAllPage from "./components/pages/AdminViewAllPage";
import TokenPing from "./components/temp/TokenPing";
import FeedbackPage from "./components/pages/FeedbackPage";
import { Typography } from "@mui/material";
import AccessBulkImportPage from "./components/access/AccessBulkImportPage";
import AccessLevelsManagementPage from "./components/pages/AccessLevelsManagementPage";
import ReviewAccessTicketPage from "./components/access/ReviewAccessTicketPage";
import AccessSelectionPage from "./components/access/AccessSelectionPage";
import AccessPaperTrailPage from "./components/access/AccessPaperTrailPage";
import AccessPendingTicketsPage from "./components/access/AccessPendingTicketsPage";
import HRISPage from "./components/hris/HRISPage";
import PermissionsPage from "./components/permissions/PermissionsPage";
import ManagedTablesPage from "./components/managed_tables/ManagedTablesPage";
import AccessRequestPage2 from "./components/access/AccessRequestPage2";
import BulkEntityImportPage from "./components/entity/BulkEntityImportPage";
import DupNamesPage from "./components/temp_feats/DupNamesPage";
import ProblematicMembersPage from "./components/temp_feats/ProblematicMembersPage";
import PendingEntityTicketsPage from "./components/entity/PendingEntityTicketsPage";
import EditEntityTicketPage from "./components/entity/EditEntityTicket";
import TagsPage from "./components/admin/TagsPage";
import { ConfigPage } from "./components/configs/ConfigPage";
import { HRISIntegrationConfigPage } from "./components/configs/hris_integration/HRISIntegrationConfigPage";
import { NoTenantProvidedPage } from "./components/app/NoTenantProvided";
import { TENANT_URL_PATH_PRECEDOR } from "./helpers/Constants";
import { HomePage } from "./components/app/HomePage";
import { TenantManagementPage } from "./components/axotrax_admin/tenant/TenantManagementPage";

export const VIEW = "View"
export const REVIEW = "Review"
export const EDIT = "Edit"
export const REVIEW_EDIT_REQ = "Review Edit Request"
export const PROVIDE_FEEDBACK = "Feedback"

export const ACCESS_REQUEST = "request_access"
export const ACCESS_VIEW = "view_access"

const PageWrapper = ({page}) => {
  const params = useParams();
  const key = JSON.stringify(params);
  return <div key={key}>{page}</div>
}

const RoutesWithinContext = () => {
  // return <HRISPage/>;
  const ext_gen = 'entity_type/:entity_type_id/eti?/:entity_type_instance_id?/stage?/:stage_id?';

  const ent_accessor_params = '/accessor_type_name?/:accessor_type_name?/accessor_eti_id?/:accessor_eti_id?/accessor_member_id?/:accessor_member_id?'
  const emp_accessor_params = '/emp_email?/:emp_email?'
  const resourceSuffix = '/res_type_name?/:res_type_name?/res_eti_id?/:res_eti_id?/res_member_id?/:res_member_id?';

  const access_entity_req_ext = `/${ACCESS_REQUEST}/:ent_or_emp${ent_accessor_params}${resourceSuffix}`;
  const access_emp_req_ext = `/${ACCESS_REQUEST}/:ent_or_emp${emp_accessor_params}${resourceSuffix}`;

  // const access_view_ext = `/${ACCESS_VIEW}/:ent_or_emp${emp_accessor_params}${ent_accessor_params}${resourceSuffix}`;
  const access_emp_view_ext = `/${ACCESS_VIEW}/:ent_or_emp${emp_accessor_params}${resourceSuffix}`;
  const access_entity_view_ext = `/${ACCESS_VIEW}/:ent_or_emp${ent_accessor_params}${resourceSuffix}`;
  const config_ext = `instance_type_id?/:instance_type_id?/attribute?/:attribute?/process?/:process_type?/edit_field_id?/:edit_field_id?/table_display?/:table_display?`
  return (
    <Routes>
      <Route path="/ping" element={<TokenPing/>} key={"ping"}/>
      <Route
        path={`/${TENANT_URL_PATH_PRECEDOR}/:tenant_ext/`}
        element={ <Layout/> }
        >
          <Route path={``} element={<HomePage/>}/>
          <Route path={`temp_feats/`} element={<Outlet/>}>
              <Route path={`dup_names`} element={<DupNamesPage/>}/>
              <Route path={`prob_members`} element={<ProblematicMembersPage/>}/>
          </Route>
          <Route path={HRIS} element={<HRISPage/>}/>
          <Route path={PROVIDE_FEEDBACK} element={<FeedbackPage/>}/>
          <Route path={`${ADMIN_NEW}/`} element={<Outlet/>}>
              <Route path={`${CONFIGS}/${config_ext}`} element={<ConfigPage/>}/>
              <Route path={HRIS_INTEGRATION} element={<HRISIntegrationConfigPage/>}/>
              <Route path={PERMISSIONS_NEW} element={<PermissionsPage/>}/>
              <Route path={MANAGED_TABLES} element={<ManagedTablesPage/>}/>
              <Route path={EMP_TAGS} element={<TagsPage/>}/>
          </Route>
          <Route path={`${ACCESS}/`} element={<Outlet/>}>
              <Route path={REQUEST} element={<AccessSelectionPage/>}/>
              <Route path={PENDING} element={<AccessPendingTicketsPage/>}/>
              {/* <Route path={VIEW} element={<AccessViewPage/>}/> */}
              <Route path={BULK_IMPORT} element={<AccessBulkImportPage/>}/>
              <Route path={MANAGE_LEVELS} element={<AccessLevelsManagementPage/>}/>
              <Route path={PAPER_TRAIL} element={<AccessPaperTrailPage/>}/>
              <Route path={`${REVIEW}/access_type/:access_type_id/ticket/:ticket_id/stage/:stage_id`} element={<PageWrapper page={<ReviewAccessTicketPage/>}/>} />
          </Route>
          <Route path={`${TASKS}/`} element={<Outlet/>}>
              <Route path={PENDING_TASKS} element={<PendingTasksPage/>}/>
              <Route path={COMPLETED_TASKS} element={<CompletedTasksPage/>}/>
          </Route>

          <Route path={`${REQUEST}/${ext_gen}`} element={<PageWrapper page={<RequestPage/>}/>}/>
          {/* <Route path={`${PENDING}/${ext_gen}`} element={<PageWrapper page={<PendingTicketsPage/>}/>}/> */}
          <Route path={`${PENDING}/${ext_gen}`} element={<PageWrapper page={<PendingEntityTicketsPage/>}/>}/>
          <Route path={`${APPROVED}/${ext_gen}`} element={<PageWrapper page={<ApprovedPage/>}/>}/>
          <Route path={`${VIEW_ALL}/${ext_gen}`} element={<PageWrapper page={<AdminViewAllPage/>}/>}/>

          <Route path={`${VIEW}/${ext_gen}`} element={<PageWrapper page={<ViewEntityPage/>}/>}/>
          <Route path={`${EDIT}/${ext_gen}`} element={<PageWrapper page={<EditEntityTicketPage/>}/>}/>

          <Route path={`${PAPER_TRAIL}/${ext_gen}`} element={<PageWrapper page={<PaperTrailPage/>}/>}/>
          <Route path={`${UPLOAD_CSV}/${ext_gen}`} element={<PageWrapper page={<BulkEntityImportPage />}/>}/>

          {/* <Route path={`/${ACCESS_REQUEST}/access_type/:access_type_id/accessor/:accessor_id/resource/:resource_id`} element={<PageWrapper page={<AccessRequestPage2/>}/>}/> */}
          <Route path={`${ACCESS_REQUEST}/access_type/:access_type_id/accessor/:accessor_id/resource/:resource_id`} element={<PageWrapper page={<AccessRequestPage2/>}/>}/>
          <Route path={`${REVIEW}/`} element={<Outlet/>}>
            <Route path={`entity_type/:entity_type_id/eti/:instance_id/ticket/:ticket_id/stage/:stage_id`} element={<PageWrapper page={<ReviewTicketPage/>}/>} />
            <Route path={`access_type/:access_type_id/accessor/:accessor_id/resource/:resource_id/ticket/:ticket_id/stage/:stage_id`} element={<PageWrapper page={<ReviewAccessTicketPage/>}/>} />
            <Route path="*" element={<Navigate to={`/${TASKS}/${PENDING_TASKS}`} replace={true}/>}/>
          </Route>
          {/* <Route path={access_view_ext} element={<AccessViewTicketPage/>}/> */}
          <Route path="*" element={<Typography variant="h5">Page Not Found</Typography>}/>
      </Route>
      <Route path='/admin' element={<TenantManagementPage/>}/>
      <Route path="*" element={<NoTenantProvidedPage/>}/>
  </Routes>    
  )
}

function App() {
  // return <TokenPing/>
  // return <UserGroupsPage/>
  // return <AccessLevelsManagementPage />
  return (
    <BrowserRouter>
      <ContextProvider>
        <RoutesWithinContext/>
      </ContextProvider>
    </BrowserRouter>
  )
  
}

export default App;

